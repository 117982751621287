@mixin modal-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .64);
  z-index: 1000000;
  display: none;

  &.modal-open {
    display: flex;
  }

  .modal-inner {
    position: relative;
    min-width: 35vw;

    .btn-close {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: transparent;
      border: none;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      width: 0.85rem;
      height: 0.85rem;

      min-width: 30px;
      min-height: 30px;

      max-width: 50px;
      max-height: 50px;
      z-index: 2;

      img {
        width: 100%;
        height: auto;
      }
    }

    .modal-content-container {
      background-color: $nude;

      .game-clue-wrapper {
        width: 75vw;
        min-width: 400px;
      }

      .modal-message-wrapper {
        padding: 0.25rem;
        margin: 0.5rem;
        p {
          @include futura-pt-book-light;
          font-size: getFontSizeRem(30);
          text-align: center;
          text-transform: uppercase;
        }
      }
      padding: .5rem;
    }

    .buttons-wrapper {
      background-color: $blue-deep;
      padding: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 4rem;
        position: relative;
        color: $orange;
        border: 1px solid $orange;
        padding: 0.25rem;
        background: transparent;
        line-height: getFontSizeRem(16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;

        span {
          font-size: getFontSizeRem(22);
          display: block;
        }
      }

      .button-cancel {
        opacity: 0.5;
        margin-right: 0;
      }
    }
  }
}
