/* Futura PT */
@font-face {
  font-family: 'FuturaPT-Medium';
  src: 
    getURL('assets/fonts/futura-pt/futura-pt-medium.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-medium.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Medium-Italic';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-medium-italic.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-medium-italic.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Bold';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-bold.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-bold.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Bold-Italic';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-bold-italic.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Book';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-book.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-book.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Book-Italic';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-book-italic.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-book-italic.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Light';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-light.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-light.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Light-Italic';
  src:
    getURL('assets/fonts/futura-pt/futura-pt-light-italic.woff2') format('woff2'),
    getURL('assets/fonts/futura-pt/futura-pt-light-italic.woff') format('woff');
}

/* Playfair-Display */
@font-face {
  font-family: 'Playfair-Display-Regular';
  src:
    getURL('assets/fonts/playfair-display/playfair-display-regular.woff2') format('woff2'),
    getURL('assets/fonts/playfair-display/playfair-display-regular.woff') format('woff');
}

@mixin play-fair-regular {
  font-family: 'Playfair-Display-Regular', serif;
  font-weight: 200;
}

@mixin futura-pt-book-light {
  font-family: "FuturaPT-Book", serif;
  font-weight: 200;
}