/* ANIM PARAMS */
$anim-time-tween: 0.5s;
$default-transition: all 0.5s ease-out;

/* SIZES */
$inner-header-height-portrait: 29%;
$inner-footer-height-portrait: 22%;
$rounded-btn-border-radius: getFontSizeRem(25) 50%;

/* SCREENS */
$bp-tablets: "screen and (min-width: 481px) and (max-width: 768px)";
$bp-mobile: "screen and (max-width: 481px)";
$bp-small-screens: "screen and (min-width: 769px) and (max-width: 1024px)";
$bp-medium-screens: "screen and (min-width: 1025px) and (max-width: 1200px)";
$bp-medium-screens-max: "screen and (min-width: 1025px) and (max-width: 1300px)";
$bp-large-screens: "screen and (min-width: 1201px)";
$bp-handheld: "screen and (max-width: 1024px)";
$mq-mobile-landscape: "screen and (orientation:landscape) and (max-device-width: 480px)";
$mq-portrait: "screen and (orientation: portrait)";

/* FONT SIZES */
$fs-min: 30;
$fs-max: 60;

/* MIXINS */
@mixin full-absolute{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
