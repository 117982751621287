$blue-deep: #131625;
$orange: #eba24e;
$orange-mate: rgba(235, 162, 78, 0.3);
$nude: #ecdbcc;
$nude-light: #efe0cd;
$beige: #f4ebdd;
$white: #ffffff;
$white-broken: #D8D8D8;
$black: #000000;
$black40: rgba(0, 0, 0, 0.4);
$red: #ff0000;