html {
  font-size: calc(30px + (60 - 30) * ((100vw - 1200px) / (1920 - 1200)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 1200px) / (1920 - 1200)));

  @media screen and (max-width: 1200px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 60px;
  }
}

body {
  background-color: $nude;
  font-family: "FuturaPT-Medium", sans-serif;
  color: $blue-deep;
}

.seamless-button {
  border: none;
  background-color: transparent;
}

.btn-disabled {
  opacity: 0.3;

  &:hover {
    border: none !important;
  }
}

.load-spinner-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 250, 0.5);

  .spinner {
    width: 100px;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    margin: 0 5px;
    background-color: $blue-deep;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}

/* Main modal */
.modal-wrapper {
  @include modal-wrapper;
}

/* Login */
.login-container {
  @include full-absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-wrapper {
    width: 25%;
    max-width: 500px;
    min-width: 400px;

    table {
      width: 100%;
      font-size: 0.6rem;

      td {
        padding: 0.1rem;
      }

      .label {
        width: 45%;
        text-align: right;
      }

      input {
        width: 100%;
      }

      .submit {
        vertical-align: top;
        text-align: right;
      }

      button {
        color: $nude;
        background-color: $blue-deep;
        font-size: getFontSizeRem(22);
        padding: 10px;

        &.disabled {
          opacity: 0.3;
        }
      }
    }

    .error-msg {
      color: $red;
      font-size: getFontSizeRem(22);
      text-align: center;
    }
  }
}

/* Main content */
.main-content {
  @include full-absolute;
  padding: 0.15rem 0.5rem;
  display: flex;
  flex-direction: column;

  .top-bar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.25rem;

    button {
      font-size: getFontSizeRem(20);
      background-color: $nude;
      color: $blue-deep;
      border: 1px solid $blue-deep;
      padding: 0.2rem 0.3rem;
    }

    .new-game {
      margin-right: 0.2rem;
      background-color: $orange;
      border: 1px solid $orange;
    }

    .btn-back {
      position: absolute;
      background-color: $black;
      color: $white;
      display: flex;
      align-items: center;
      left: 0;

      img {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  header {
    h1 {
      font-family: "FuturaPT-Book", sans-serif;
      font-size: 0.65rem;
      text-transform: uppercase;
    }
  }

  .inner-content {
    flex: 1;
    padding: 0.25rem 0;
    font-size: getFontSizeRem(22);
    overflow-y: auto;

    .error-msg {
      color: $red;
      font-size: getFontSizeRem(22);
    }
  }

  .game-list-container {

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.175rem;
      text-transform: uppercase;
      font-family: "FuturaPT-Medium", sans-serif;

      tr {
        padding: 5px;
      }

      tr:nth-child(even) {
        background: $nude-light;
      }
      tr:nth-child(odd) {
        background: $beige;
      }

      td {
        padding: 5px;

        button {
          font-size: getFontSizeRem(22);
          padding: 0;
          border-bottom: solid 1px rgba($blue-deep, 0%);;

          &:hover {
            border-bottom: solid 1px $blue-deep;
          }
        }
      }

      .col-date {
        width: 23%;
        padding-left: 1rem;

        .date {
          display: inline-block;
          width: getFontSizeRem(120);
        }
      }

      .col-name {
        width: 54%;
      }

      .col-cta {
        width: 23%;
        text-align: center;
      }

      @media screen and (max-width: 815px) {
        .col-date {
          width: 30%;
        }

        .col-name {
          width: 47%;
        }
      }
    }
  }

  .game-container {
    display: flex;
    flex-direction: column;

    .form-wrapper {
      flex: 1;
      overflow-y: auto;
      width: 100%;
      padding-right: 0.125rem;

      .form {
        width: 100%;
        text-transform: uppercase;

        .form-line {
          display: flex;
        }

        .field-label {
          padding: 5px 0.25rem;
          width: 40%;
          margin: 0 0.175rem 0.175rem 0;
          background: $nude-light;
        }

        .field-value {
          flex: 1;
          margin: 0 0.175rem 0.175rem 0;
        }

        .error-field {
          border: solid 1px $red;
        }

        input {
          font-size: getFontSizeRem(22);
          width: 100%;
          text-transform: uppercase;
        }

        select {
          padding: 6px 0;
          border: none;
          width: 21%;

          @media screen and (max-width: 1024px) {
            width: 40%;
          }
        }

        .input-date-select {
          background-color: transparent;

          .wrapper {
            font-size: getFontSizeRem(22);
            background-color: $white;
            display: flex;
            align-items: center;
            padding: 7px;
            text-transform: uppercase;
            width: 100%;
            text-align: left;
          }

          img {
            display: inline-block;
            width: 0.65rem;
          }
        }

        .timezone-value {
          background-color: transparent;
        }

        .guest-line, .team-name-line {
          height: 100%;
        }

        .team-wrapper {
          margin-bottom: 0.125rem;
        }

        .guest-email, .guest-email-value, .team-name, .team-name-value {
          margin: 0 0.175rem 0.1rem 0;
        }

        .guest-email {
          font-family: "FuturaPT-Light-Italic", sans-serif;
          text-align: right;
        }

        .guest-email-value {
          input {
            font-family: "FuturaPT-Light-Italic", sans-serif;
            text-transform: lowercase;
            padding: 3px 7px;
          }

        }
      }

      .mode-view-wrapper {
        width: 100%;

        section {
          margin-bottom: 0.25rem;
        }

        .game-team-block {
          padding: 0.125rem 0;
        }

        .game-team-block:nth-child(2n) {
          background: $nude-light;
        }

        .view-line {
          display: flex;
          align-items: center;
        }

        .label {
          padding: 3px 0.25rem;
          width: 20%;
          margin: 0 0.1rem 0.1rem 0;
          text-align: right;
        }

        .value {
          flex: 1;
          margin: 0 0.1rem 0.1rem 0;
          font-family: FuturaPT-Light, sans-serif;
        }

        .guest-label, .guest-value {
          font-family: FuturaPT-Light-Italic, sans-serif;
        }

        .game-name-value, .team-name-value, .team-code-value {
          text-transform: uppercase;
        }
      }
    }

    .btn-form {
      background-color: $black;
      color: $white;
      font-size: getFontSizeRem(22);
      padding: 0.125rem 0.25rem;
      min-width: 3rem;
    }

    .btn-add-team {
      padding: 0;
      background-color: transparent;
      color: $blue-deep;
      min-width: unset;
    }

    .btn-validate {
      margin-right: 0.25rem;
    }

    .actions-wrapper {
      padding: 0.25rem 0 0;
    }
  }
}

/* Calendar */
.react-datepicker-wrapper {
  width: 21%;

  @media screen and (max-width: 1024px) {
    width: 40%;
  }
}
.react-datepicker {
  font-family: FuturaPT-Medium, sans-serif;
  font-size: getFontSizeRem(20);
  border-radius: 0;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 0.5rem;
  line-height: 0.5rem;
  margin: 0.1rem;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 0.35rem;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.react-datepicker__navigation {
  border: 0.2rem solid transparent;
  top: 0.3rem;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover
{
  border-radius: 0;
  background-color: $orange;
  color: #fff;
}

.react-datepicker__header {
  padding-top: 0.3rem;
  background-color: $nude-light;
  border-top-left-radius: 0;
};

.react-datepicker__month {
  margin: 0.3rem;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 0.5rem;
  line-height: 0.5rem;
  margin: 0.15rem;
}

.react-datepicker__day-names {
  margin-top: 0.2rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: getFontSizeRem(30);
  padding: 0.15rem 0.2rem;
}

.react-datepicker__time-container {
  width: 2rem;
}

.react-datepicker__navigation--next {
  border-left-color: $blue-deep;
}

.react-datepicker__navigation--previous {
  border-right-color: $blue-deep;
}


.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 2rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 2rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $orange;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: $orange;
}

/* Toast */
.toast-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(19, 22, 37, 0.5);
  text-align: center;
  font-family: FuturaPT-Medium, sans-serif;
  font-size: getFontSizeRem(22);
  text-transform: uppercase;
  padding: 0.25rem;
  color: $orange;
  display: none;

  &.show {
    display: block;
  }

  &.error {
    color: $red;
  }
}